import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`GitHub Teams gratis: `}<a parentName="li" {...{
          "href": "https://github.blog/2020-04-14-github-is-now-free-for-teams/"
        }}>{`https://github.blog/2020-04-14-github-is-now-free-for-teams/`}</a></li>
      <li parentName="ul">{`ProtonMailBridge nu open source: `}<a parentName="li" {...{
          "href": "https://protonmail.com/blog/bridge-open-source/"
        }}>{`https://protonmail.com/blog/bridge-open-source/`}</a></li>
      <li parentName="ul">{`System76 laptop "Lemur Pro": `}<a parentName="li" {...{
          "href": "https://system76.com/laptops/lemur"
        }}>{`https://system76.com/laptops/lemur`}</a></li>
      <li parentName="ul">{`TOR påverkas av COVID-19: `}<a parentName="li" {...{
          "href": "https://blog.torproject.org/covid19-impact-tor"
        }}>{`https://blog.torproject.org/covid19-impact-tor`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Valorant anti cheat "Vanguard": `}<a parentName="li" {...{
          "href": "https://www.riotgames.com/en/news/a-message-about-vanguard-from-our-security-privacy-teams"
        }}>{`https://www.riotgames.com/en/news/a-message-about-vanguard-from-our-security-privacy-teams`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Tips på versionshanterad hemkatalog: `}<a parentName="li" {...{
          "href": "https://www.atlassian.com/git/tutorials/dotfiles"
        }}>{`https://www.atlassian.com/git/tutorials/dotfiles`}</a></li>
      <li parentName="ul">{`Proton: 6000 spel till Linux på 2 år: `}<a parentName="li" {...{
          "href": "https://boilingsteam.com/proton-brought-about-6000-games-to-linux-so-far"
        }}>{`https://boilingsteam.com/proton-brought-about-6000-games-to-linux-so-far`}</a></li>
      <li parentName="ul">{`PiHole: `}<a parentName="li" {...{
          "href": "https://pi-hole.net/"
        }}>{`https://pi-hole.net/`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Vim`}</h3>
    <ul>
      <li parentName="ul">{`Alex: ciw, shift+%, d%, c%, /sök, 4dd, A, etcetc`}<ul parentName="li">
          <li parentName="ul">{`Försöker upptäcka och undvika "smell" (repetitioner, hjkl)`}</li>
          <li parentName="ul">{`Copy/paste`}</li>
        </ul></li>
      <li parentName="ul">{`Improving Vim speed: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=OnUiHLYZgaA"
        }}>{`https://www.youtube.com/watch?v=OnUiHLYZgaA`}</a></li>
      <li parentName="ul">{`Mastering Vim: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=wlR5gYd6um0"
        }}>{`https://www.youtube.com/watch?v=wlR5gYd6um0`}</a></li>
      <li parentName="ul">{`VimTricks: `}<a parentName="li" {...{
          "href": "https://vimtricks.substack.com/"
        }}>{`https://vimtricks.substack.com/`}</a></li>
    </ul>
    <h3>{`PinePhone + Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Alex ska nog prova senaste Ubuntu Touch`}</li>
    </ul>
    <h3>{`Contributor-ligan:`}</h3>
    <ul>
      <li parentName="ul">{`Alex 1 + 4 = 5 bronspoäng`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Podcast-spelare som är Open Source? (Undrar Josefine): `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/List_of_podcatchers"
        }}>{`https://en.wikipedia.org/wiki/List_of_podcatchers`}</a></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      